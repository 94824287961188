import { Sort } from '@angular/material/sort';
import { PagingOptions } from '../../../shared/page.models';
import { ScanListFilters } from './scan-list.state';

export class SetSort {
  static readonly type = '[ScanListState] SetSort';
  constructor(public readonly payload: Sort) {}
}

export class SetPaging {
  static readonly type = '[ScanListState] SetPaging';
  constructor(public readonly payload: PagingOptions) {}
}

export class SetFilters {
  static readonly type = '[ScanListState] SetFilters';
  constructor(public readonly payload: ScanListFilters) {}
}
