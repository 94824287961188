import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Action, Select, Selector, State, StateContext } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { distinctUntilChanged, Observable } from 'rxjs';
import { Region } from '../../../region/region.models';
import { RegionState } from '../../../region/region.state';
import { PagingOptions } from '../../../shared/page.models';
import { SetFilters, SetPaging, SetSort } from './scan-list.actions';

export interface ScanListFilters {
  pointcloudId?: string;
  uploadedDateFrom?: Date;
  uploadedDateTo?: Date;
  projectId?: string;
  name?: string;
  status?: string;
  uploadedByTIDUuid?: string;
}

export interface ScanListStateModel {
  sort: Sort;
  paging: PagingOptions;
  filters: ScanListFilters;
}

const defaultState: ScanListStateModel = {
  sort: { active: 'uploadedDate', direction: 'desc' },
  paging: { size: 10, index: 0 },
  filters: {},
};

@State<ScanListStateModel>({
  name: 'scanListState',
  defaults: defaultState,
})
@Injectable()
export class ScanListState {
  @Select(RegionState.activeRegion) activeRegion$!: Observable<Region | undefined>;

  @Selector() static sort(state: ScanListStateModel): Sort {
    return state.sort;
  }

  @Selector() static paging(state: ScanListStateModel): PagingOptions {
    return state.paging;
  }

  @Selector() static filters(state: ScanListStateModel): ScanListFilters {
    return state.filters;
  }

  @Selector() static filterCount(state: ScanListStateModel): number {
    return Object.values(state.filters).filter(
      (value) => isDefined(value) && value.toString().length > 0,
    ).length;
  }

  ngxsAfterBootstrap(ctx: StateContext<ScanListStateModel>): void {
    this.activeRegion$
      .pipe(distinctUntilChanged((prev, current) => prev?.regionCode === current?.regionCode))
      .subscribe(() => {
        const resetPaging: PagingOptions = { ...ctx.getState().paging, index: 0 };
        ctx.patchState({ paging: resetPaging });
      });
  }

  @Action(SetSort) setSort(ctx: StateContext<ScanListStateModel>, { payload }: SetSort) {
    ctx.patchState({ sort: payload });
  }

  @Action(SetPaging) setPaging(ctx: StateContext<ScanListStateModel>, { payload }: SetPaging) {
    ctx.patchState({ paging: payload });
  }

  @Action(SetFilters) setFilters(ctx: StateContext<ScanListStateModel>, { payload }: SetFilters) {
    ctx.patchState({ filters: payload });
  }
}
